import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { Context, Plugin } from '@segment/analytics-next';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/remix';
import { isbot } from 'isbot';
import { hydrateRoot } from 'react-dom/client';

const analytics = AnalyticsBrowser.load({
  writeKey: window.ENV.SEGMENT_WRITE_KEY,
});

function stripUrlParams(ctx: Context) {
  if (ctx.event.context) {
    const FORBIDDEN_PARAMS = ['access_token', 'refresh_token', 'id_token', 'token', 'code', 'state', 'nonce'];

    // Remove sensitive URL parameters from `url` and `search` fields
    if (ctx.event.context.page && ctx.event.context.page.url) {
      const url = new URL(ctx.event.context.page.url);

      FORBIDDEN_PARAMS.forEach((param) => {
        if (url.searchParams.has(param)) url.searchParams.delete(param);
      });

      ctx.event.context.page.url = url.href;
      ctx.event.context.page.search = url.search;
    }
  }

  return ctx;
}

const stripSensitiveUrlParams: Plugin = {
  name: 'Strip Sensitive URL Parameters',
  version: '1.0.0',
  type: 'before',
  isLoaded: () => true,
  load: () => Promise.resolve(),
  track: stripUrlParams,
  identify: stripUrlParams,
};

analytics.register(stripSensitiveUrlParams);

const isBot = isbot(window.navigator.userAgent);

if (!isBot && window.ENV.SITE_ENV !== 'local') {
  datadogRum.init({
    applicationId: window.ENV.DATADOG_APPLICATION_ID,
    clientToken: window.ENV.DATADOG_CLIENT_TOKEN,
    site: window.ENV.DATADOG_SITE,
    service: window.ENV.DATADOG_SERVICE,
    env: window.ENV.DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    // @ts-expect-error - Looks like `sampleRate` is deprecated or something
    sampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['clickmap'],
    defaultPrivacyLevel: 'mask',
    allowedTracingOrigins: [
      'https://app-yonder-prod-ne-harp01.azurewebsites.net',
      'https://app-yonder-prod-ne-lute01.azurewebsites.net',
      'https://api.yonder.app',
      'https://app-develop-dev-ne-harp01.azurewebsites.net',
      'https://app-develop-dev-ne-lute01.azurewebsites.net',
      'https://develop.api.yondering.app',
      'https://app-yonder-sandbox-ne-harp01.azurewebsites.net',
      'https://app-yonder-sandbox-ne-lute01.azurewebsites.net',
      'https://api.sandbox.yonder.app',
    ],
  });

  datadogRum.startSessionReplayRecording();

  Sentry.init({
    environment: window.ENV.SITE_ENV,
    dsn: window.ENV.SENTRY_DSN,
    tracesSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],
    // ...
  });
}

const hydrate = () => {
  React.startTransition(() => {
    hydrateRoot(
      document,
      <React.StrictMode>
        <RemixBrowser />
      </React.StrictMode>,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  window.setTimeout(hydrate, 1);
}

export { analytics };
